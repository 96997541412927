html, body { /*reset */
    color: #282828;
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 300;
}
h1,
h2,
h3,
h4 {
    color: #010101;
    font-weight: 700;
}

/** txt colours **/
.highlight {
    color: #013974; /* default blue */
    /* category colours set in main */
}

/* txt sizes */
.big {
    display: inline-block;
    font-size: 30px;
    font-weight: bolder;
    margin: 30px 0;
}
.small {
    font-size: 12px;
}

/** buttons **/
.button {
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF;
    background-color: #013974; /* default blue */
    padding: 10px 20px;
    max-width: 100%;
    border: 2px solid #013974;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
.button:hover {
    background-color: #FFF;
    color: #013974;
}

#content a[href $='.pdf']{ /* pdf links as grey buttons */
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: #FFF;
    background-color: #a2a3a7 !important; /* grey */
    padding: 10px 20px;
    max-width: 100%;
    border: 0 !important;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}
#content a[href $='.pdf']:hover {
    background-color: #242c41 !important;
    color: #FFF !important;
}

/** blocks **/
.half-col,
.staff-col {
    display:inline-block;
    vertical-align:top;
}
.half-col {
    width:47%;
    min-width:500px;
    margin:1%;
}
.page-content .staff-table,
.staff-table {
    text-align:center;
}
.page-content .staff-col,
.staff-col {
    width:17%;
    min-width:200px;
    margin:1%;
    text-align:center;
}
.page-content .staff-col p,
.page-content .staff-col h1,
.page-content .staff-col h2,
.page-content .staff-col h3,
.page-content .staff-col h4 {
    text-align:center;
}

@media only screen and (max-width:760px){
    .half-col,
    .staff-col {
        display: block;
        width: 100%;
        padding: 0;
        margin: 20px 0;
    }
}